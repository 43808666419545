import SocialIcons from "../SocialIcons/SocialIcons";

export default function SocialBar(props: any) {
  const config = props.viewData?.config;
  let backgroundImageConfig = undefined;

  if (config?.rgba1 && config?.rgba2 && config?.backgroundImageURL)
    backgroundImageConfig = `linear-gradient(${config.rgba1}, ${config.rgba2}), url(${config.backgroundImageURL})`;
  else if (config?.rgba1 && config?.rgba2)
    backgroundImageConfig = `linear-gradient(${config.rgba1}, ${config.rgba2})`;
  else if (config?.backgroundImageURL)
    backgroundImageConfig = `url(${config.backgroundImageURL})`;

  return (
    <div
      id={props?.anchorID}
      className={`bg-opacity-25 bg-cover ${config?.additionalTailwindStyles}`}
      style={{
        background: props?.viewData?.backgroundColor
          ? props.viewData.backgroundColor
          : "#eee",
        backgroundImage: backgroundImageConfig,
      }}
    >
      <div className="max-w-7xl py-8 px-4 sm:py-6 sm:px-6 lg:px-8 mx-auto">
        <div className="lg:grid lg:grid-cols-2 lg:gap-8">
          <div className="flex items-center justify-center">
            <h2
              style={{ color: props?.viewData?.titleColor }}
              className="max-w-md mx-auto text-2xl sm:text-3xl font-extrabold text-center lg:max-w-xl lg:text-left uppercase"
            >
              {props?.viewData?.title}
            </h2>
          </div>
          <div className="flow-root self-center mt-8 lg:mt-0">
            <div className="flex flex-col md:flex-row  md:space-y-0 md:flex-wrap justify-center lg:ml-4 xl:ml-8 ">
              {props.viewData.socials.map((item: any) => (
                <SocialIcons
                  props={item}
                  divClasses={
                    "flex flex-grow flex-shrink-0 justify-center lg:flex-grow-0 lg:ml-2 items-center py-1 sm:py-0"
                  }
                  linkClasses={
                    "font-bold text-xl sm:text-2xl space-x-2 flex items-center"
                  }
                  imgClasses={"inline w-7"}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
