import React from "react";
import ios from "../../../assets/app-store-badge.svg";
import google from "../../../assets/google-play-badge.png";
import { Link } from "react-router-dom";

export default function DownloadOurApp(props: any) {
  const config = props.viewData?.config;
  let backgroundImageConfig = undefined;

  if (config?.rgba1 && config?.rgba2 && config?.backgroundImageURL)
    backgroundImageConfig = `linear-gradient(${config.rgba1}, ${config.rgba2}), url(${config.backgroundImageURL})`;
  else if (config?.rgba1 && config?.rgba2)
    backgroundImageConfig = `linear-gradient(${config.rgba1}, ${config.rgba2})`;
  else if (config?.backgroundImageURL)
    backgroundImageConfig = `url(${config.backgroundImageURL})`;

  return (
    <div
      id={props.anchorID}
      className={`px-4 sm:px-6 lg:pb-28 lg:px-8 bg-cover pt-4 ${config?.additionalTailwindStyles}`}
      style={{
        backgroundColor: "#eee",
        backgroundImage: backgroundImageConfig,
      }}
    >
      <div className="relative overflow-hidden max-w-lg mx-auto lg:max-w-7xl h-auto">
        <div className="flex flex-col items-center justify-center">
          <h2 className="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl">
            {props.viewData?.title}
          </h2>
          <div
            style={{ borderColor: props.viewData.config?.divideColor }}
            className="mt-3 mb-2 border-b-4 w-1/6"
          ></div>
        </div>
        <div className="mt-3 h-auto grid gap-4 pt-2 lg:grid-cols-2 lg:gap-x-2 overflow-hidden">
          <div className="flex flex-col items-center">
            <img
              className=" h-auto w-1/2"
              src={props.viewData?.mockup}
              alt={props?.viewData?.config?.alt}
            />
          </div>
          <div className="flex flex-col justify-center text-center items-center overflow-hidden max-h-96">
            <p className="text-xl w-3/4">
              <strong>{props.viewData.config?.description}</strong>
            </p>
            <Link to={"/" + props.viewData.config?.feature} className="mt-4">
              <button
                type="button"
                className="inline-flex items-center px-4 py-2 border border-gray-800 shadow-sm text-base font-medium rounded-md text-gray-800 bg-white hover:bg-gray-50 focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                View More Info
              </button>
            </Link>
            <a
              href={props.viewData.config?.iosLink}
              rel="noopener noreferrer"
              target={"_blank"}
              className=""
            >
              <img
                className="w-40 mt-3"
                alt="Click to view our app in the apple store"
                src={ios}
              />
            </a>
            <a
              href={props.viewData.config?.androidLink}
              rel="noopener noreferrer"
              target={"_blank"}
              className=""
            >
              <img
                className="w-44 mt-1"
                alt="Click to view our app in the google play store"
                src={google}
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
