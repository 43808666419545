import "core-js/stable";
import "core-js/es/map";
import "regenerator-runtime/runtime";
import React from "react";
import OCVManifestModel from "./OCVManifestModel";
import TopBar from "../components/MainComponents/TopBar/TopBar";
import NavBar from "../components/MainComponents/NavBar/NavBar";
import Slider from "../components/MainComponents/Slider/Slider";
import FeatureBar from "../components/MainComponents/FeatureBar/FeatureBar";
import { Route, Switch } from "react-router-dom";
import OCVPage from "../components/OCVFeatures/OCVPage/OCVPage";
import OCVPhotoGallery from "../components/OCVFeatures/OCVPhotoGallery/OCVPhotoGallery";
import { Helmet } from "react-helmet";
import OCVBlog from "../components/OCVFeatures/OCVBlog/OCVBlog";
import SummaryImage from "../components/MainComponents/SummaryImage/SummaryImage";
import Summary from "../components/MainComponents/Summary/Summary";
import OCVForm from "../components/OCVFeatures/OCVForm/OCVForm";
import OCVContacts from "../components/OCVFeatures/OCVContacts/OCVContacts/OCVContacts";
import Footer from "../components/MainComponents/Footer/Footer";
import FormFooter from "../components/MainComponents/FormFooter/FormFooter";
import ViewOurApp from "../components/MainComponents/ViewOurApp/ViewOurApp";
import Breadcrumb from "../components/MainComponents/Breadcrumb/Breadcrumb";
import GoogleSearch from "../components/MainComponents/GoogleSearch/GoogleSearch";
import OCVCalendar from "../components/OCVFeatures/OCVCalendar/OCVCalendar";
import OCVMap from "../components/OCVFeatures/OCVMap/OCVMap";
import OCVSexOffendersMap from "../components/OCVFeatures/OCVSexOffendersMap/OCVSexOffendersMap";
import Label from "../components/MainComponents/Label";
import ImageBar from "../components/MainComponents/ImageBar";
import TwoColumnView from "../components/MainComponents/TwoColumnView/TwoColumnView";
import HorizontalSublayout from "../components/MainComponents/HorizontalSublayout/HorizontalSublayout";
import Iframe from "react-iframe";
import OCVBlogSlider from "../components/OCVFeatures/OCVBlog/OCVBlogSlider/OCVBlogSlider";
import OCVSubmenuPage from "../components/OCVFeatures/OCVSubmenuPage/OCVSubmenuPage";
import OCVAlerts from "../components/OCVFeatures/OCVAlerts/OCVAlerts";
import SocialBar from "../components/MainComponents/SocialBar/SocialBar";
import PageView from "../components/MainComponents/PageView/PageView";
import DownloadOurApp from "../components/MainComponents/DownloadOurApp/DownloadOurApp";
import OCVButtonBar from "../components/OCVFeatures/OCVButtonBar/OCVButtonBar";
import OCVBlogCardWidget from "../components/OCVFeatures/OCVBlogCardWidget/OCVBlogCardWidget";
import BlogCarousel from "../components/OCVFeatures/OCVBlogCarousel/OCVBlogCarousel";
import OCVBlogGridWidget from "../components/OCVFeatures/OCVBlogGridWidget/OCVBlogGridWidget";
import BlogFAQ from "../components/OCVFeatures/OCVBlogFAQ/OCVBlogFAQ";
import BlogListContainer from "../components/OCVFeatures/OCVBlogListWidget/BlogListContainer";
import OCVBlogGridPage from "../components/OCVFeatures/OCVBlogGridPage/OCVBlogGridPage";
import OCVLargeSubmenuPage from "../components/OCVFeatures/OCVLargeSubmenuPage/OCVLargeSubmenuPage";

interface OCVRoute {
  path: string;
  exact: boolean;
  page: JSX.Element[];
}

export default class OCVManifest {
  manifestData!: OCVManifestModel;

  static buildMainPage(manifestData: any, styles: any) {
    let views: any = manifestData["views"];
    let mainPage: JSX.Element[] = [];
    for (let i = 0; i < manifestData["homeOrder"].length; i++) {
      let view = views[manifestData["homeOrder"][i]];
      switch (view["type"]) {
        case "topbar":
          mainPage.push(
            <TopBar
              key={manifestData["homeOrder"][i]}
              classes={styles}
              manifestData={manifestData}
              topbarConfig={view["config"]}
              anchorID={manifestData["homeOrder"][i]}
            />
          );
          break;
        case "label":
          mainPage.push(
            <Label
              key={manifestData["homeOrder"][i]}
              labelConfig={view["config"]}
              anchorID={manifestData["homeOrder"][i]}
            />
          );
          break;
        case "webNavbar":
          mainPage.push(
            <NavBar
              key={manifestData["homeOrder"][i]}
              classes={styles}
              manifestData={manifestData}
              navbarConfig={view["config"]}
              anchorID={manifestData["homeOrder"][i]}
            />
          );
          break;
        case "slider":
          mainPage.push(
            <Slider
              key={manifestData["homeOrder"][i]}
              sliderConfig={view["config"]}
            />
          );
          break;
        case "imageBar":
          mainPage.push(
            <ImageBar
              key={manifestData["homeOrder"][i]}
              classes={styles}
              imageBarConfig={view["config"]}
              manifestData={manifestData}
              anchorID={manifestData["homeOrder"][i]}
            />
          );
          break;
        case "featureBar":
          mainPage.push(
            <FeatureBar
              key={manifestData["homeOrder"][i]}
              manifestData={manifestData}
              features={manifestData["features"]}
              classes={styles}
              featureBarConfig={view["config"]}
              featureBar={view}
              anchorID={manifestData["homeOrder"][i]}
            />
          );
          break;
        case "footer":
          mainPage.push(
            <Footer
              key={manifestData["homeOrder"][i]}
              classes={styles}
              manifestData={manifestData}
              footerConfig={view["config"]}
              anchorID={manifestData["homeOrder"][i]}
            />
          );
          break;
        case "formFooter":
          mainPage.push(
            <FormFooter
              key={manifestData["homeOrder"][i]}
              manifestData={manifestData}
              footerConfig={view["config"]}
              formConfig={view}
              anchorID={manifestData["homeOrder"][i]}
            />
          );
          break;
        case "twoColumnView":
          mainPage.push(
            <TwoColumnView
              key={manifestData["homeOrder"][i]}
              classes={styles}
              manifestData={manifestData}
              layoutConfig={view["config"]}
              anchorID={manifestData["homeOrder"][i]}
            />
          );
          break;
        case "horizontalLayout":
          mainPage.push(
            <HorizontalSublayout
              key={manifestData["homeOrder"][i]}
              classes={styles}
              manifestData={manifestData}
              config={view["config"]}
              anchorID={manifestData["homeOrder"][i]}
            />
          );
          break;
        case "summaryImage":
          mainPage.push(
            <SummaryImage
              key={manifestData["homeOrder"][i]}
              viewData={view}
              manifestData={manifestData}
              anchorID={manifestData["homeOrder"][i]}
            />
          );
          break;
        case "summary":
          mainPage.push(
            <Summary
              key={manifestData["homeOrder"][i]}
              viewData={view}
              manifestData={manifestData}
              anchorID={manifestData["homeOrder"][i]}
            />
          );
          break;
        case "socialBar":
          mainPage.push(
            <SocialBar
              key={manifestData["homeOrder"][i]}
              viewData={view}
              manifestData={manifestData}
              anchorID={manifestData["homeOrder"][i]}
            />
          );
          break;
        case "pageView":
          mainPage.push(
            <PageView
              key={manifestData["homeOrder"][i]}
              viewData={view}
              manifestData={manifestData}
              anchorID={manifestData["homeOrder"][i]}
            />
          );
          break;
        case "downloadOurApp":
          mainPage.push(
            <DownloadOurApp
              key={manifestData["homeOrder"][i]}
              viewData={view}
              manifestData={manifestData}
              anchorID={manifestData["homeOrder"][i]}
            />
          );
          break;
        case "blogCarousel":
          mainPage.push(
            <BlogCarousel
              key={manifestData["homeOrder"][i]}
              viewData={view}
              manifestData={manifestData}
              anchorID={manifestData["homeOrder"][i]}
            />
          );
          break;
        case "buttonBar":
          mainPage.push(
            <OCVButtonBar
              key={manifestData["homeOrder"][i]}
              viewData={view}
              manifestData={manifestData}
              anchorID={manifestData["homeOrder"][i]}
            />
          );
          break;
        case "blogGrid":
          mainPage.push(
            <OCVBlogGridWidget
              key={manifestData["homeOrder"][i]}
              viewData={view}
              anchorID={manifestData["homeOrder"][i]}
            />
          );
          break;
        case "blogFAQ":
          mainPage.push(
            <BlogFAQ
              key={manifestData["homeOrder"][i]}
              viewData={view}
              link={view.url}
              anchorID={manifestData["homeOrder"][i]}
            />
          );
          break;
        case "blogCard":
          mainPage.push(
            <OCVBlogCardWidget
              key={manifestData["homeOrder"][i]}
              viewData={view}
              anchorID={manifestData["homeOrder"][i]}
            />
          );
          break;
        default:
          break;
      }
    }
    return mainPage;
  }

  static buildWebPage(
    manifestData: any,
    classes: any,
    component: JSX.Element,
    title: string
  ) {
    let views = manifestData["views"];
    let webPage: JSX.Element[] = [];
    webPage.push(
      <Helmet key={title}>
        <title>
          {title} | {`Washington County Sheriff's Office, FL`}
        </title>
      </Helmet>
    );
    for (let i = 0; i < manifestData["baseLayout"].length; i++) {
      if (manifestData["baseLayout"][i] === "{component}") {
        webPage.push(
          <Breadcrumb key={title + i} classes={classes} title={title} />,
          component
        );
      } else {
        let view = views[manifestData["baseLayout"][i]];
        switch (view["type"]) {
          case "topbar":
            webPage.push(
              <TopBar
                key={title + manifestData["baseLayout"][i]}
                manifestData={manifestData}
                classes={classes}
                topbarConfig={view["config"]}
                anchorID={manifestData["baseLayout"][i]}
              />
            );
            break;
          case "webNavbar":
            webPage.push(
              <NavBar
                key={title + manifestData["baseLayout"][i]}
                classes={classes}
                manifestData={manifestData}
                navbarConfig={view["config"]}
                anchorID={manifestData["baseLayout"][i]}
              />
            );
            break;
          case "label":
            webPage.push(
              <Label
                key={title + manifestData["baseLayout"][i]}
                labelConfig={view["config"]}
                anchorID={manifestData["baseLayout"][i]}
              />
            );
            break;
          case "slider":
            webPage.push(
              <Slider
                key={title + manifestData["baseLayout"][i]}
                sliderConfig={view["config"]}
              />
            );
            break;
          case "imageBar":
            webPage.push(
              <ImageBar
                key={title + manifestData["baseLayout"][i]}
                classes={classes}
                imageBarConfig={view["config"]}
                manifestData={manifestData}
                anchorID={manifestData["baseLayout"][i]}
              />
            );
            break;
          case "featureBar":
            webPage.push(
              <FeatureBar
                key={title + manifestData["baseLayout"][i]}
                classes={classes}
                manifestData={manifestData}
                featureBarConfig={view["config"]}
                anchorID={manifestData["baseLayout"][i]}
              />
            );
            break;
          case "footer":
            webPage.push(
              <Footer
                key={title + manifestData["baseLayout"][i]}
                classes={classes}
                manifestData={manifestData}
                footerConfig={view["config"]}
                anchorID={manifestData["baseLayout"][i]}
              />
            );
            break;
          case "formFooter":
            webPage.push(
              <FormFooter
                key={manifestData["baseLayout"][i]}
                footerConfig={view["config"]}
                manifestData={manifestData}
                formConfig={view}
                anchorID={manifestData["baseLayout"][i]}
              />
            );
            break;
          case "twoColumnView":
            webPage.push(
              <TwoColumnView
                key={title + manifestData["baseLayout"][i]}
                classes={classes}
                manifestData={manifestData}
                layoutConfig={view["config"]}
                anchorID={manifestData["baseLayout"][i]}
              />
            );
            break;
          case "horizontalLayout":
            webPage.push(
              <HorizontalSublayout
                key={title + manifestData["baseLayout"][i]}
                classes={classes}
                manifestData={manifestData}
                config={view["config"]}
                anchorID={manifestData["baseLayout"][i]}
              />
            );
            break;
          case "summaryImage":
            webPage.push(
              <SummaryImage
                key={title + manifestData["baseLayout"][i]}
                viewData={view}
                manifestData={manifestData}
                anchorID={manifestData["baseLayout"][i]}
              />
            );
            break;
          case "summary":
            webPage.push(
              <Summary
                key={title + manifestData["baseLayout"][i]}
                viewData={view}
                manifestData={manifestData}
                anchorID={manifestData["baseLayout"][i]}
              />
            );
            break;
          case "socialBar":
            webPage.push(
              <SocialBar
                key={title + manifestData["baseLayout"][i]}
                viewData={view}
                manifestData={manifestData}
                anchorID={manifestData["baseLayout"][i]}
              />
            );
            break;
          case "pageView":
            webPage.push(
              <PageView
                key={title + manifestData["baseLayout"][i]}
                manifestData={manifestData}
                viewData={view}
                anchorID={manifestData["baseLayout"][i]}
              />
            );
            break;
          case "downloadOurApp":
            webPage.push(
              <DownloadOurApp
                key={title + manifestData["baseLayout"][i]}
                viewData={view}
                manifestData={manifestData}
                anchorID={manifestData["baseLayout"][i]}
              />
            );
            break;
          case "blogCarousel":
            webPage.push(
              <BlogCarousel
                key={title + manifestData["baseLayout"][i]}
                viewData={view}
                manifestData={manifestData}
                anchorID={manifestData["baseLayout"][i]}
              />
            );
            break;
          case "buttonBar": //no other component from OCVFeatures is in this part, making sure is right
            webPage.push(
              <OCVButtonBar
                key={title + manifestData["baseLayout"][i]}
                manifestData={manifestData}
                viewData={view}
                anchorID={manifestData["baseLayout"][i]}
              />
            );
            break;
          case "blogGrid": //no other component from OCVFeatures is in this part, making sure is right
            webPage.push(
              <OCVBlogGridWidget
                key={title + manifestData["baseLayout"][i]}
                viewData={view}
                manifestData={view}
                anchorID={manifestData["baseLayout"][i]}
              />
            );
            break;
          case "blogCard":
            webPage.push(
              <OCVBlogCardWidget
                key={title + manifestData["baseLayout"][i]}
                viewData={view}
                anchorID={manifestData["baseLayout"][i]}
              />
            );
            break;
          case "blogFAQ":
            webPage.push(
              <BlogFAQ
                key={manifestData["baseLayout"][i]}
                viewData={view}
                link={view.url}
                anchorID={manifestData["baseLayout"][i]}
              />
            );
            break;
          default:
            webPage.push(
              <Breadcrumb
                key={title + manifestData["baseLayout"][i]}
                title={title}
                anchorID={manifestData["baseLayout"][i]}
              />,
              component
            );
            break;
        }
      }
    }
    return webPage;
  }

  static buildPagesFromFeatures(manifestData: any, classes: any) {
    let features = manifestData["features"];
    const featureKeys = Object.keys(features);
    let response: OCVRoute[] = [];
    for (const key of featureKeys) {
      let feature = features[key];
      switch (feature["type"]) {
        case "page":
          response.push({
            path: key,
            exact: true,
            page: this.buildWebPage(
              manifestData,
              classes,
              <OCVPage
                viewData={feature["config"]}
                key={key + "page"}
                subtypes={feature["subtypes"] ? feature["subtypes"] : []}
                classes={classes}
                link={feature["url"]}
                anchorID={key}
              />,
              feature.title
            ),
          });
          break;
        case "photoGallery":
          response.push({
            path: key,
            exact: true,
            page: this.buildWebPage(
              manifestData,
              classes,
              <OCVPhotoGallery
                key={key + "photo-gallery"}
                classes={classes}
                link={feature["url"]}
                config={feature["config"]}
                anchorID={key}
              />,
              feature.title
            ),
          });
          break;
        case "blog":
          response.push({
            path: key,
            exact: true,
            page: this.buildWebPage(
              manifestData,
              classes,
              <OCVBlog
                submitATipLink={
                  feature.hasOwnProperty("other") &&
                  feature["other"].hasOwnProperty("submitATipLink") &&
                  feature["other"]["submitATipLink"] === true
                }
                key={key + "blog"}
                config={feature["config"]}
                subtypes={feature["subtype"]}
                classes={classes}
                route={key}
                link={feature["url"]}
                title={feature.title}
                anchorID={key}
              />,
              feature.title
            ),
          });
          break;
        case "form":
          response.push({
            path: key,
            exact: true,
            page: this.buildWebPage(
              manifestData,
              classes,
              <OCVForm
                viewData={feature["config"]}
                classes={classes}
                formID={feature["formID"]}
                link={feature["url"]}
                headerText={feature["headerText"] && feature["headerText"]}
                key={key + "form"}
                submissionText={
                  feature["submissionText"]
                    ? feature["submissionText"]
                    : "This form has been submitted successfully. Please refresh the page to submit this form again."
                }
                anchorID={key}
              />,
              feature.title
            ),
          });
          break;
        case "contacts":
          response.push({
            path: key,
            exact: true,
            page: this.buildWebPage(
              manifestData,
              classes,
              <OCVContacts
                viewData={feature["config"]}
                title={feature["title"]}
                link={feature["url"]}
                showSearch={feature["showSearch"]}
                classes={classes}
                subtypes={feature["subtypes"] ? feature["subtypes"] : []}
                key={key + "contacts"}
                anchorID={key}
              />,
              feature.title
            ),
          });
          break;
        case "calendar":
          response.push({
            path: key,
            exact: true,
            page: this.buildWebPage(
              manifestData,
              classes,
              <OCVCalendar
                viewData={feature["config"]}
                key={key + "-calendar"}
                link={feature["url"]}
                anchorID={key}
              />,
              feature.title
            ),
          });
          break;
        case "map":
          response.push({
            path: key,
            exact: true,
            page: this.buildWebPage(
              manifestData,
              classes,
              <OCVMap
                config={feature.config}
                key={key + "-map"}
                link={feature["url"]}
                anchorID={key}
              />,
              feature.title
            ),
          });
          break;
        case "sexOffendersMap":
          response.push({
            path: key,
            exact: true,
            page: this.buildWebPage(
              manifestData,
              classes,
              <OCVSexOffendersMap
                config={feature.config}
                anchorID={key}
                key={key + "-offenderMap"}
                link={feature["url"]}
              />,
              feature.title
            ),
          });
          break;
        case "appMarketing":
          response.push({
            path: key,
            exact: true,
            page: this.buildWebPage(
              manifestData,
              classes,
              <ViewOurApp
                key={"viewOurApp"}
                title={"View Our App"}
                manifest={manifestData}
                config={feature.other}
                classes={classes}
                anchorID={key}
              />,
              feature.title
            ),
          });
          break;
        case "blogSlider":
          response.push({
            path: key,
            exact: true,
            page: this.buildWebPage(
              manifestData,
              classes,
              <OCVBlogSlider
                anchorID={key}
                key={key + "blogSlider"}
                config={feature["config"]}
                link={feature["url"]}
              />,
              feature.title
            ),
          });
          break;
        case "blogCard":
          response.push({
            path: key,
            exact: true,
            page: this.buildWebPage(
              manifestData,
              classes,
              <OCVBlogCardWidget
                anchorID={key}
                key={key + "blogCard"}
                viewData={feature}
              />,
              feature.title
            ),
          });
          break;
        case "webview":
          response.push({
            path: key,
            exact: true,
            page: this.buildWebPage(
              manifestData,
              classes,
              <Iframe
                position={"relative"}
                key={key + "webview"}
                url={feature["url"] ? feature["url"] : "http://www.google.com"}
                width={"100%"}
                height={"700px"}
                id={key}
              />,
              feature.title
            ),
          });
          break;
        case "blogGridPage":
          response.push({
            path: key,
            exact: true,
            page: this.buildWebPage(
              manifestData,
              classes,
              <OCVBlogGridPage
                key={key + "blogGridPage"}
                url={feature["url"]}
                viewData={feature}
                anchorID={key}
              />,
              feature.title
            ),
          });
          break;
        case "submenu":
          response.push({
            path: key,
            exact: true,
            page: this.buildWebPage(
              manifestData,
              classes,
              <OCVSubmenuPage
                key={key + "submenu"}
                items={feature["items"]}
                manifestData={manifestData}
                config={feature["config"]}
                classes={classes}
                anchorID={key}
              />,
              feature.title
            ),
          });
          break;
        case "largeSubmenu":
          response.push({
            path: key,
            exact: true,
            page: this.buildWebPage(
              manifestData,
              classes,
              <OCVLargeSubmenuPage
                key={key + "submenu"}
                items={feature["items"]}
                config={feature["config"]}
                manifestData={manifestData}
                classes={classes}
                anchorID={key}
              />,
              feature.title
            ),
          });
          break;
        case "alerts":
          response.push({
            path: key,
            exact: true,
            page: this.buildWebPage(
              manifestData,
              classes,
              <OCVAlerts
                key={key + "alerts"}
                link={feature["url"]}
                route={key}
                config={feature["config"]}
                manifestData={manifestData}
                classes={classes}
                anchorID={key}
              />,
              feature.title
            ),
          });
          break;
        case "blogGrid":
          response.push({
            path: key,
            exact: true,
            page: this.buildWebPage(
              manifestData,
              classes,
              <OCVBlogGridWidget
                key={key + "buttonBar"}
                manifestData={feature}
                viewData={feature}
                anchorID={key}
              />,
              feature.title
            ),
          });
          break;
        case "blogList":
          response.push({
            path: key,
            exact: true,
            page: this.buildWebPage(
              manifestData,
              classes,
              <BlogListContainer
                key={key + "blog"}
                route={key}
                manifestData={feature}
                anchorID={key}
                sortOrder={feature.config.sort}
                showSort={feature.config.showSort}
              />,
              feature.title
            ),
          });
          break;
        case "blogFAQ":
          response.push({
            path: key,
            exact: true,
            page: this.buildWebPage(
              manifestData,
              classes,
              <BlogFAQ
                key={key + "blogFAQ"}
                viewData={feature}
                showSearch={feature["showSearch"]}
                link={feature.url}
                anchorID={key}
              />,
              feature.title
            ),
          });
          break;
      }
    }
    return response;
  }

  static buildRoutes(manifestData: any, classes: any) {
    let routes = this.buildPagesFromFeatures(manifestData, classes);
    return (
      <Switch>
        {routes.map((route: OCVRoute, index: number) => (
          <Route
            key={index + route.path + index}
            path={"/" + route.path}
            render={() => route.page}
          />
        ))}
        <Route
          key={"search"}
          path={"/search"}
          exact={true}
          render={() =>
            this.buildWebPage(manifestData, classes, <GoogleSearch />, "Search")
          }
        />
        <Route
          key={"home-abrtybu"}
          path={"/"}
          render={() => this.buildMainPage(manifestData, classes)}
        />
      </Switch>
    );
  }
}
