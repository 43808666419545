import React from "react";

export default function Pagination(props) {
  let { onClick } = props;
  return (
    <span
      style={{
        background:
          props.selectedBubble === props.pageKey
            ? props.paginationColor.selectedInnerColor
            : props.paginationColor.InnerColor,
        border:
          props.selectedBubble === props.pageKey
            ? props.paginationColor.selectedOuterColor
            : props.paginationColor.OuterColor,
      }}
      className="relative 2xl:w-5 2xl:h-5 h-4 w-4 lg:w-2.5 lg:h-2.5 flex flex-nowrap items-center justify-center border-2 rounded-full mx-2.5 lg:mx-2 cursor-pointer"
      onClick={onClick}
      key={props.pageKey}
    >
      <div
        className="h-4 w-4 lg:h-2.5 lg:w-2.5 rounded-full"
        aria-hidden="true"
      />
    </span>
  );
}
