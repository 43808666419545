import React from "react";
import default_image from "../../../assets/default_image.jpeg";

export default function OCVBlogGridPage(props: any) {
  const { viewData } = props;

  const [blogData, setBlogData] = React.useState<any>([]);

  React.useEffect(() => {
    fetch(viewData.url)
      .then((response) => response.json())
      .then((data) => setBlogData(data));
  }, [viewData.url]);

  let cols = viewData.config.numCols;

  const width =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;

  width < 640 && (cols = 3);
  640 < width && width < 1300 && (cols = 4);

  const config = viewData?.config;
  let backgroundImageConfig = undefined;

  if (config?.rgba1 && config?.rgba2 && config?.backgroundImageURL)
    backgroundImageConfig = `linear-gradient(${config.rgba1}, ${config.rgba2}), url(${config.backgroundImageURL})`;
  else if (config?.rgba1 && config?.rgba2)
    backgroundImageConfig = `linear-gradient(${config.rgba1}, ${config.rgba2})`;
  else if (config?.backgroundImageURL)
    backgroundImageConfig = `url(${config.backgroundImageURL})`;

  return (
    <div
      id={props.anchorID}
      className={"pb-4 bg-cover h-auto " + config?.additionalTailwindStyles}
      style={{
        backgroundColor: viewData?.config?.bgColor
          ? viewData.config.bgColor
          : "#eee",
        backgroundImage: backgroundImageConfig,
      }}
    >
      <div
        className={`grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-${cols} pt-4`}
      >
        {blogData.map((item: any, index: number) => (
          <div key={item?.title + index} className="col-span-1 mx-2 my-3">
            <div className="pb-4 ">
              {cols === 3 && (
                <img
                  alt=""
                  src={
                    item?.images[0]?.large
                      ? item?.images[0]?.large
                      : default_image
                  }
                  className="w-auto h-11/12 object-cover mx-auto"
                  style={{ height: "400px" }}
                />
              )}
              {cols === 4 && (
                <img
                  alt=""
                  src={
                    item?.images[0]?.large
                      ? item?.images[0]?.large
                      : default_image
                  }
                  className="w-auto object-cover mx-auto"
                  style={{ height: "400px" }}
                />
              )}
              {cols === 5 && (
                <img
                  alt=""
                  src={
                    item?.images[0]?.large
                      ? item?.images[0]?.large
                      : default_image
                  }
                  className="w-auto h-11/12 object-cover mx-auto"
                  style={{ height: "400px" }}
                />
              )}
              {cols === 6 && (
                <img
                  alt=""
                  src={
                    item?.images[0]?.large
                      ? item?.images[0]?.large
                      : default_image
                  }
                  className="w-auto h-11/12 object-cover mx-auto"
                  style={{ height: "400px" }}
                />
              )}
            </div>
            <div
              className={`w-full mx-auto text-${viewData.config.titleColor} text-lg font-semibold lg:text-xl text-center leading-3 pb-2`}
            >
              {`${item?.title}`}
            </div>
            <div className="flex flex-row">
              <button
                className={`w-1/2 mx-auto border-2 font-medium border-${viewData.config.buttonBorderColor} text-${viewData.config.buttonTextColor} p-2`}
              >
                {viewData.config.buttonText}
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
