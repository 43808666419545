import React from "react";
import ReactPlayer from "react-player";
import "./OCVVideoPlayer.css";

export default function OCVVideoPlayer(props: any) {
  const { config } = props;
  return (
    <div className="OCVVideoPlayerDiv">
      <ReactPlayer
        controls={true}
        width="100%"
        height="400px"
        url={config["link"]}
      />
    </div>
  );
}
