import React from "react";
import Feature from "./Feature/Feature";
import { Row } from "reactstrap";
import "./FeatureBar.css";

export default function FeatureBar(props: any) {
  const { featureBarConfig, features, manifestData, classes } = props;
  let featureItems = featureBarConfig.items.map((item: any) =>
    features && features[item.feature] ? (
      <Feature
        manifestData={manifestData}
        bg={featureBarConfig.bg}
        url={
          features[item.feature]["url"] !== null
            ? features[item.feature]["url"]
            : ""
        }
        subtype={
          features[item.feature]["subtype"] !== null
            ? features[item.feature].subtype
            : []
        }
        iconViewType={item.iconViewType ? item.iconViewType : "fad"}
        image={item.image !== null ? item.image : ""}
        type={features[item.feature].type}
        feature={item.feature}
        key={item.title}
        classes={classes}
        width={item.width}
        height={item.height}
        color={featureBarConfig.itemColor}
        iconType={item.icon}
        title={item.title}
      />
    ) : (
      ""
    )
  );
  return (
    <div className={featureBarConfig?.additionalTailwindStyles}>
      {props.featureBar?.featureBarTitle && (
        <div
          className="text-center font-bold text-white text-2xl mt-2"
          style={{ backgroundColor: manifestData.stylesheet.colors.primary }}
        >
          {props.featureBar.featureBarTitle}
        </div>
      )}
      <Row id={props.anchorID} className={classes.featureBar}>
        <div className="featureBarDiv w-full flex flex-row flex-wrap">
          {featureItems}
        </div>
      </Row>
    </div>
  );
}
