import React from "react";

export default function OCVBlogGridWidget(props: any) {
  const { viewData } = props;

  const [blogData, setBlogData] = React.useState<any>([]);

  React.useEffect(() => {
    fetch(viewData.url, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => setBlogData(data));
  }, [viewData]);

  const config = viewData?.config;
  let backgroundImageConfig = undefined;

  if (config?.rgba1 && config?.rgba2 && config?.backgroundImageURL)
    backgroundImageConfig = `linear-gradient(${config.rgba1}, ${config.rgba2}), url(${config.backgroundImageURL})`;
  else if (config?.rgba1 && config?.rgba2)
    backgroundImageConfig = `linear-gradient(${config.rgba1}, ${config.rgba2})`;
  else if (config?.backgroundImageURL)
    backgroundImageConfig = `url(${config.backgroundImageURL})`;

  return (
    <>
      <div
        className={`pb-4 bg-cover ${config?.additionalTailwindStyles}`}
        style={{
          backgroundColor: viewData?.config?.bgColor
            ? viewData.config.bgColor
            : "#eee",
          backgroundImage: backgroundImageConfig,
        }}
      >
        <div
          className="w-full h-16 text-5xl text-center font-bold pt-4"
          style={{
            color: viewData.config.titleColor
              ? viewData.config.titleColor
              : "black",
          }}
          id={props.anchorID}
        >
          {viewData.title}
        </div>
        <hr
          className="mx-auto w-32 mt-4 py-1"
          style={{
            background: viewData.config.hrColor
              ? viewData.config.hrColor
              : "black",
          }}
        />

        <div className={`grid grid-cols-2 lg:grid-cols-6 pt-4`}>
          {blogData.map((blog: any) => (
            <div className="col-span-1 mx-2 my-3" key={blog?.title}>
              <div className="pb-4 object-contain">
                <a href={`https://${blog?.title}`}>
                  <img
                    alt={blog?.title}
                    src={blog?.images[0]?.large}
                    className="w-auto h-11/12 object-contain mx-auto"
                  />
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
