import React from "react";

export default function PageView(props: any) {
  const [pageData, setPageData] = React.useState<any>([]);
  React.useEffect(() => {
    fetch(props.manifestData["features"][props.viewData["feature"]]["url"], {
      mode: "cors",
    })
      .then((response) => response.json())
      .then((data) => {
        setPageData(data);
      });
  }, [props.manifestData, props.viewData]);

  const config = props.viewData?.config;
  let backgroundImageConfig = undefined;

  if (config?.rgba1 && config?.rgba2 && config?.backgroundImageURL)
    backgroundImageConfig = `linear-gradient(${config.rgba1}, ${config.rgba2}), url(${config.backgroundImageURL})`;
  else if (config?.rgba1 && config?.rgba2)
    backgroundImageConfig = `linear-gradient(${config.rgba1}, ${config.rgba2})`;
  else if (config?.backgroundImageURL)
    backgroundImageConfig = `url(${config.backgroundImageURL})`;

  return (
    <div
      id={props.anchorID}
      className={`bg-cover ${config?.additionalTailwindStyles}`}
      style={{
        backgroundColor: "#eee",
        backgroundImage: backgroundImageConfig,
      }}
    >
      {props.manifestData["features"][props.viewData["feature"]]?.subtype[0] ===
        "imageAtTop" && (
        <>
          <div className="relative py-6">
            <div
              className="absolute inset-0 flex flex-col"
              aria-hidden="true"
            ></div>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 flex flex-row justify-center h-auto w-full">
              <img
                className="relative h-40 w-40 rounded-full shadow-lg"
                src={pageData?.data?.images[0].large}
                alt={props?.viewData?.alt}
              />
            </div>
          </div>
          <div className="mt-4 mx-auto max-w-7xl px-4 sm:mt-24 sm:px-6">
            <div className="text-center">
              <h1 className="text-xl flex flex-col items-center tracking-tight font-extrabold text-gray-900 sm:text-3xl md:text-4xl">
                <span className="block">{pageData?.data?.title}</span>
                <div
                  style={{ borderColor: props.viewData.divideColor }}
                  className="border-b-4 mt-3 w-1/5 justify-self-center"
                ></div>
              </h1>
              <div
                dangerouslySetInnerHTML={{ __html: pageData?.data?.content }}
                className="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl"
              ></div>
            </div>
          </div>
        </>
      )}

      {props.manifestData["features"][props.viewData["feature"]]?.subtype[0] !==
        "imageAtTop" && (
        <>
          <div className="mt-16 mx-auto max-w-7xl px-4 sm:mt-24 sm:px-6">
            <div className="text-center">
              <h1 className="text-2xl flex flex-col items-center tracking-tight font-extrabold text-gray-900 sm:text-3xl md:text-4xl">
                <span className="block">{pageData?.data?.title}</span>
                <div
                  style={{ borderColor: props.viewData.divideColor }}
                  className="border-b-4 mt-3 w-1/5 justify-self-center"
                ></div>
              </h1>
              <div
                dangerouslySetInnerHTML={{ __html: pageData?.data?.content }}
                className="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:max-w-4xl"
              ></div>
            </div>
          </div>
          <div className="relative mt-4">
            <div className="flex flex-col" aria-hidden="true">
              <div className="flex-1"></div>
              <div className="flex-1 w-full bg-white"></div>
            </div>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 flex flex-row justify-center mb-16">
              <img
                style={{ borderColor: "#C18B53" }}
                className="relative h-40 w-40 rounded-full shadow-lg border-4 border-black"
                src={pageData?.data?.images[0]?.large}
                alt={props?.viewData?.alt}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
}
