import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import defaultImage from "../../../../assets/default_image.jpeg";

export const DetailView = ({ member, handleBack }) => {
  return (
    <div className="flex flex-col md:h-96 overflow-y-scroll w-full p-2">
      <button
        className="w-36 p-2 border-2 rounded-full text-white"
        onClick={handleBack}
        style={{ backgroundColor: "#085b9e" }}
      >
        Back to List
      </button>
      <div className="flex flex-row">
        <div className="w-40 p-2">
          <img
            src={
              member?.images?.length === 0 ? defaultImage : member?.images[0]
            }
            alt={`${member?.title}`}
          />
        </div>
        <div className="p-2 space-y-1">
          <p>{member?.title}</p>
          <p>{member?.subtitle}</p>
          <p>{member?.address}</p>
          {member?.phone?.map((item) =>
            item.name.toLowerCase() === "phone" ? (
              <p key={item.number}>
                Phone:
                <a href={`tel:${item.number}`}> {item.number}</a>
              </p>
            ) : item.name.toLowerCase().includes("fax") ? (
              <p key={item.number}>
                Fax:
                <a href={`tel:${item.number}`}> {item.number}</a>
              </p>
            ) : null
          )}
          {member?.email?.map((email) => (
            <a key={email} href={`mailto: ${email}`}>
              Send Email
            </a>
          ))}
          <p>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`http://maps.google.com/?q=${member?.address}`}
            >
              Get Directions
            </a>
            {isNaN(member?.distance) ? "" : ` - ${member?.distance} miles away`}
          </p>

          <div>
            <p dangerouslySetInnerHTML={{ __html: member?.description }} />
          </div>
          {member?.facebookLink ? (
            <a
              target={"_blank"}
              rel="noopener noreferrer"
              href={member.facebookLink}
            >
              <FontAwesomeIcon icon={["fab", "facebook"]} />
            </a>
          ) : null}
          {member?.twitterHandle ? (
            <a
              target={"_blank"}
              rel="noopener noreferrer"
              href={`https://twitter.com/${member.twitterHandle}`}
            >
              <FontAwesomeIcon icon={["fab", "twitter"]} />
            </a>
          ) : null}
          {member?.links?.map((link) => (
            <a key={link} href={link}>
              Other Websites: {link}
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};
